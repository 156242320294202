export default {
	init() {
		console.log("annuaires-js loaded");

		const params = {
			action: "filter_annuaire",
			mots: [],
			category: -1,
			search: "",
			post_type: jQuery(".cpt").val(),
		};

		$("#submit-filter-annuaire").on("click", function (e) {
			e.preventDefault;

			var filter = $("#filter-annuaires");
			var loading = $(".load-bar");

			$.ajax({
				url: filter.attr("action"),
				data: params,
				type: "POST",
				action: "ajax_function_annuaires",
				beforeSend: function () {
					$(".annuaires").html("");
					loading.toggleClass("invisible");
				},
				success: function (data) {
					loading.toggleClass("invisible");
					$(".annuaires").html(data);
				},
			});
			return false;
		});

		jQuery("#terms").change(function () {
			params["search"] = jQuery("#terms").val();
		});

		jQuery("select").change(function () {
			var $select = jQuery(this).val();
			params["category"] = $select;

			console.log(params);
		});
		jQuery(".popular-category").each(function () {
			var label = $(this).find($(".selectit"));
			var input = $(this).find($("input"));
			var inputId = input.attr("id");
			label.attr("for", inputId);
			input.insertBefore(label);
		});

		jQuery(".list_category input").each(function () {
			jQuery(this).prop("disabled", false);
		});

		jQuery(".mots li").each(function () {
			var mot = jQuery(this);
			mot.on("click", function () {
				jQuery(".mots li").removeClass("selected");
				params["mots"] = [];
				mot.toggleClass("selected");
				if (
					mot.hasClass("selected") &&
					params.hasOwnProperty("mots") &&
					!params["mots"].includes(mot.data("letter"))
				) {
					params["mots"].push(mot.data("letter"));
				} else {
					params["mots"].splice(params["mots"].indexOf(mot.data("letter")), 1);
				}
				console.log(params);
			});
		});
	},
};

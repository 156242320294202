import datepicker from 'js-datepicker';
import LazyLoad from 'vanilla-lazyload';

export default {
	init() {
		console.log('single-js loaded');

		const params = {
			action: 'filter_archive',
			post_type: jQuery('.post_type').val(),
			categories: [],
			communes: '',
			date_debut: '',
			date_fin: '',
			search: ''
		};
		const months = [
			'janvier',
			'février',
			'mars',
			'avril',
			'mai',
			'juin',
			'juillet',
			'août',
			'septembre',
			'octobre',
			'novembre',
			'décembre'
		];
		const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Vend', 'Sam'];

		const start = datepicker('#date_debut', {
			id: 1,
			startDay: 1,
			customMonths: months,
			customDays: days,
			formatter: (input, date, instance) => {
				const value = date.toLocaleDateString('fr-FR');
				input.value = value; // => '1/1/2099'
			},
			onSelect: (instance, date) => {
				params['date_debut'] = date.toLocaleDateString('fr-FR');
				jQuery('.form__period input').prop('checked', false);
				params['dates'] = [];
				ajaxArchive(params);
			}
		});
		const end = datepicker('#date_fin', {
			id: 1,
			startDay: 1,
			customMonths: months,
			customDays: days,
			formatter: (input, date, instance) => {
				const value = date.toLocaleDateString('fr-FR');
				input.value = value; // => '1/1/2099'
			},
			onSelect: (instance, date) => {
				params['date_fin'] = date.toLocaleDateString('fr-FR');
				jQuery('.form__period input').prop('checked', false);
				params['dates'] = [];
				ajaxArchive(params);
			}
		});

		jQuery('.input-container input').change(function() {
			params['search'] = jQuery('#Recherche').val();
			params['date_debut'] = jQuery('#date_debut').val();
			params['date_fin'] = jQuery('#date_fin').val();

			ajaxArchive(params);
		});
		jQuery('.communes').change(function() {
			params['communes'] = jQuery('.communes').val();
			ajaxArchive(params);
		});

		jQuery('.checkbox_container input').change(function() {
			var $input = jQuery(this);
			var key = 'categories';
			if (
				$input.prop('checked') &&
				params.hasOwnProperty(key) &&
				!params[key].includes($input.val())
			) {
				params[key].push($input.val());
			} else {
				params[key].splice(params[key].indexOf($input.val()), 1);
			}
			console.log(params);
			ajaxArchive(params);
		});

		function ajaxArchive(params) {
			var filter = $('#filter-archive');
			$.ajax({
				url: filter.attr('action'),
				data: params,
				type: 'POST',
				beforeSend: function() {
					$('.loading').addClass('visible');
					$('.filter__posts').html('');
				},
				success: function(data) {
					$('.loading').removeClass('visible');
					$('.filter__posts').html(data);

					new LazyLoad({
						elements_selector: '.lazy'
					});
				}
			});
			return false;
		}
	}
};
